
/*=============================================
=            blog            =
=============================================*/

.blog-section{
    .sidebar{
        @media #{$tablet-device}{
            margin-top: 50px;
        }

        @media #{$large-mobile}{
            margin-top: 30px;
        }
    }
}

.blog-grid-wrapper{
    margin-bottom: -40px;
}


.blog-post-slider{
    &__area{
        @media #{$large-mobile}{
            margin-bottom: 40px;
        }
    }

    &__single-slide{
        &--grid-view{
            margin-bottom: 40px;
            
            &:hover{
                .blog-post-slider__image{
                    a{
                        &:before{
                            background-color: rgba(0,0,0,.3);
                        }
                    }
                }
            }

        }
    }
    &__image{
        a{
            position: relative;
            display: block;
            width: 100%;
            &:hover{
                &:before{
                    background-color: rgba(0,0,0,.3);
                }
            }
            img{
                width: 100%;
            }
            &:before{
                transition: $transition--cubic;
                display: inline-block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
            }
        }
    }

    &__content{
        line-height: 1;
        .post-meta{
            margin-bottom: 15px;
        }
        .post-date{
            margin-bottom: 25px;
            font-weight: 700;
            font-size: 13px;
            color: #b5b5b5;
            letter-spacing: 1px;
        }
        .post-title{
            font-size: 24px;
            margin-bottom: 20px;
            a{
                color: $theme-color--black;
                transition: $transition--cubic;
                &:hover{
                    color: $theme-color--default;
                }
            }
        }

        .post-excerpt{
            font-family: $poppins;
            line-height: 1.8;
            color: #5a5a5a;
        }

        .post-category{
            display: inline-block;
            a{
                font-size: 13px;
                transition: $transition--cubic;
                font-weight: 700;
                color: $theme-color--default;
                letter-spacing: 1px;
                &:hover{
                    color: $theme-color--black;
                }
            }
        }
    }

}


/*-- Blog --*/

/*-- Blog Details --*/
.blog-details {
    & .blog-inner {
        & .media {
            margin-bottom: 30px;
            & .image {
                display: block;
                & img {
                    width: 100%;
                }
            }
        }
        & .content {
            & .meta {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px;
                & li {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    text-transform: capitalize;
                    margin-bottom: 5px;
                    &::after {
                        content: "-";
                        margin: 0 10px;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    & a {
                        transition: $transition--cubic;
                        &:hover{
                            color: $theme-color--default;
                        }
                    }
                }
            }
            & .title {
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 0;
                // Responsive
                @media #{$large-mobile}{
                    font-size: 24px;
                }
                @media #{$extra-small-mobile}{
                    font-size: 20px;
                }
                & a {
                    transition: $transition--cubic;
                    &:hover{
                        color: $theme-color--default;
                    }
                }
            }
            & .desc {
                margin-top: 20px;
                & p {
                    font-family: $poppins;
                }
            }
            & .tags {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                & li {
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 24px;
                    margin-right: 15px;
                    &::after {
                        content: ",";
                        margin-left: 3px;
                    }
                    &:first-child, &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    & i {
                        font-size: 18px;
                        line-height: 24px;
                    }
                    & a {
                        display: block;
                        transition: $transition--cubic;
                    &:hover{
                        color: $theme-color--default;
                    }
                    }
                }
            }
        }
    }
}


.blog-gallery{
    position: relative;

    .ht-swiper-button{
        &-nav{
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: $theme-color--default;
            border-color: $theme-color--default;
            color: $white;
            visibility: hidden;
            opacity: 0;
            transition: $transition--cubic;
        }

        &-prev{
            left: 30px;
        }
        
        &-next{
            right: 30px;

        }
    }

    &:hover{
        .ht-swiper-button{
            &-nav{
                visibility: visible;
                opacity: 1;
            }
    
            &-prev{
                left: 20px;
            }
            
            &-next{
                right: 20px;
    
            }
        }
    }
}

/*-- Comment Wrap --*/
.comment-wrapper {
    & h3 {
        margin-bottom: 30px;
    }
}
/*-- Comment Form --*/
.comment-form {
    & input {
        width: 100%;
        height: 50px;
        border: 1px solid #eeeeee;
        padding: 5px 20px;
    }
    & textarea {
        width: 100%;
        height: 120px;
        border: 1px solid #eeeeee;
        padding: 10px 20px;
        resize: none;
    }
    & input[type="submit"], & button, & .submit {
        width: auto;
        height: 50px;
        border: none;
        padding: 5px 30px;
        background-color: $theme-color--default;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        transition: $transition--cubic;
        margin-top: 15px;
        &:hover {
            background-color: $heading-text-color;
        }
    }
}


blockquote.blockquote {
    background-color: #f1f2f3;
    padding: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden; }
    blockquote.blockquote::before {
      position: absolute;
      content: url(/assets/img/icons/quote-left.png);
      left: -5px;
      top: -10px;
      z-index: -1;
      opacity: 0.07; }
    blockquote.blockquote p {
      font-size: 18px;
      font-style: italic; }
    blockquote.blockquote .author {
      font-size: 14px;
      display: block;
      line-height: 18px; }

/*=====  End of blog  ======*/

