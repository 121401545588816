/*=============================================
=            CSS Index            =
=============================================

    -> Helper CSS
    -> Default CSS
    -> Spacing
    -> Header
    -> Footer
    -> Sidebar
    -> Button
    -> Section title
    -> Backgrounds
    -> Scroll top
    -> Breadcrumb
    -> Pagination
    -> Preloader
    -> Hero slider
    -> Service grid slider
    -> Video cta
    -> Project slider
    -> Team
    -> Testimonial slider
    -> Service tab
    -> Fun fact
    -> Feature icon
    -> Project gallery
    -> Fun fact
    -> Blog
    -> About
    -> Service
    -> Project
    -> Contact
    -> 404
    -> Landing page
    
 
/*=====  End of CSS Index  ======*/

@import '0-base/variables';
@import '0-base/mixins';
@import '0-base/rfs';
@import '0-base/helper';
@import '1-global/default';
@import '1-global/spacing';
@import '2-layout/header';
@import '2-layout/footer';
@import '2-layout/sidebar';
@import '3-elements/button';
@import '3-elements/section-title';
@import '3-elements/backgrounds';
@import '3-elements/scroll-top';
@import '3-elements/breadcrumb';
@import '3-elements/pagination';
@import '3-elements/preloader';
@import '4-sections/hero-slider';
@import '4-sections/service-grid-slider';
@import '4-sections/video-cta';
@import '4-sections/project-slider';
@import '4-sections/team';
@import '4-sections/testimonial-slider';
@import '4-sections/service-tab';
@import '4-sections/fun-fact';
@import '4-sections/feature-icon';
@import '4-sections/project-gallery';
@import '5-pages/blog';
@import '5-pages/about';
@import '5-pages/service';
@import '5-pages/project';
@import '5-pages/contact';
@import '5-pages/404';
@import '5-pages/landing';
label {
  width: 6rem;
  clear: right;
  text-align: right;
  padding-right: 20px;
}

input,
label {
  height: 2.4rem;
  display: inline-flex;
  float: left;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

textarea {
  height: 4.4rem;
  display: inline-flex;
  float: left;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
}
input {
  padding: 10px;
  padding-top: 20px;
  border-radius: 5px;
}
#employmentForm {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

#employmentScreen {
  margin-top: 2rem;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
#inputItem {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}
#submitButton {
  background-color: #479cd2;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
}
#employmentInfo {
  max-width: 365px;
  text-align: center;
  font-size: 0.87rem;
  font-style: italic;
  background-color: #c4c4c432;

  padding: 10px;
  border: none;
  border-radius: 5px;
}
::-webkit-input-placeholder {
  /* Edge */
  font-size: 0.8rem;
  font-weight: 300;
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 0.8rem;
  font-weight: 300;
  font-style: italic;
}

::placeholder {
  font-size: 0.8rem;
  font-weight: 300;
  font-style: italic;
}
#phoneLink {
  color: #479cd2;
  text-decoration: none;
}
input {
  width: 100%;
  height: 50px;
  border: 1px solid #eeeeee;
  padding: 5px 20px;
}
textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #eeeeee;
  padding: 5px 20px;
}
.contact-information ul li {
  display: inline-flex !important;
  flex-wrap: wrap !important;
  // margin-bottom: 30px !important;
  justify-content: center !important;
  flex-direction: row !important;
  align-content: center !important;
  align-items: center !important;
}
#attachresume {
  max-width: 365px;
}
#singlePosition {
  border: 1px solid #9a9a9a;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  width: 278px;
  align-content: center;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
#singlePosition:hover {
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  width: 278px;
  cursor: pointer;
  justify-content: center;
  box-shadow: 0 14px 28px rgba(99, 99, 99, 0.25);
}
#singlePosition_selected {
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-content: center;
  width: 278px;
  align-items: center;
  justify-content: center;
  background-color: #479dd275;
}

#succesDiv {
  max-width: 365px;
  border: 1px solid #c5c5c5;
  padding: 10px;
  border-radius: 13px;
  color: white;
  background: #006170;
  font-weight: 600;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
#acounts {
  text-align: center;
}
