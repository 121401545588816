
/*=============================================
=            backgrounds            =
=============================================*/


/* background images */

.funfact-bg{
    background-image: url("/assets/img/projects/KONE1842-HDR.jpg");
}

.testimonial-slider-area-bg{
    background-image: url("/assets/img/projects/KONE1952.jpg");
}
.breadcrumb-bg{
    background-image: url("/assets/img/projects/KONE2176-HDR.jpg");
}
.breadcrumb-bg1{
    background-image: url("/assets/img/projects/KONE2291.jpg");
}
.breadcrumb-bg2{
    background-image: url("/assets/img/projects/KONE1952.jpg");
}
.breadcrumb-bg3{
    background-image: url("/assets/img/projects/KONE2140-HDR.jpg");
}
.breadcrumb-bg4{
    background-image: url("/assets/img/projects/KONE2371-HDR.jpg");
}

.white-bg{
    background-color: #ffffff;
}

.grey-bg{
    background-color: #fff;
    &--style2{
        background-color: #eeeeee;
    }
    &--style3{
        background-color: #f5f6fa;
    }
}

.dark-bg{
    background-color: #004172;
    &--style2{
        background-color: #11202D;
    }
    &--style3{
        background-color: $theme-color--black;
    }
    &--style4{
        background-color: #06112C;
    }
    &--style5{
        background-color: #05103B;
    }
}

.default-bg{
    background-color: $theme-color--default;
}

/*=====  End of backgrounds  ======*/
